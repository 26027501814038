.footer-container{
    position: relative;
}
.footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 20rem;
    gap: 4rem;
    align-items: center;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo>img{
    width: 10rem;
}
.blur-f-l{
    width: 22rem;
    height: 30rem;
    bottom: 0;
    right: 15%;
    filter: blur(200px);
    background: red;
}
.blur-f-r{
    width: 22rem;
    height: 30rem;
    left: 15%;
    background: rgb(255,112,0);
    bottom: 0;
    filter: blur(200px);
}
@media screen and (max-width:768px) {
    .logo-f>img{
        width: 14rem;
    }
}
